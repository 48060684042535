import { ReactElement, useState } from 'react';
import { IComponentMapFields, IPageLocationFields } from 'types/contentful';
import MapWrapper from 'components/UI/Map';
import MapMarker from 'components/UI/MapMarker';
import LocationMarkerSvg from 'components/UI/MapMarker/LocationMarkerSvg';
import InfoCard from 'components/UI/InfoCard';
import RichText from 'components/UI/Richtext';
import type { Entry } from 'contentful';

export interface MapComponentProps extends Omit<Omit<IComponentMapFields, 'locations'>, 'id'> {
  /** List of location items */
  nearbyLocations?: Entry<IPageLocationFields>[];

  /** Center location co ordinate */
  content?: IPageLocationFields;

  /** Hide Eye Icon */
  hideEyeIcon: boolean;

  /** Marker Pin Icon Type */
  markerIcon?: string;
}

export default function Map({
  bannerText,
  content,
  defaultZoom,
  nearbyLocations,
  hideEyeIcon,
  markerIcon = 'Eye-V01',
}: MapComponentProps): ReactElement {
  const [activeLocation, setActiveLocation] = useState<null | number>(null);
  return (
    <div className="mb-10 ">
      {bannerText && (
        <RichText
          document={bannerText}
          classNames="pb-10 prose prose-sm sm:prose lg:prose-lg max-w-none px-4 py-6 lg:py-10 md:px-20 lg:px-32 text-lg break-words"
        />
      )}
      {nearbyLocations && content && (
        <div className="lg:mx-32 mb-10" style={{ height: '500px' }}>
          <MapWrapper
            defaultZoom={defaultZoom}
            center={{
              lat: content.map.lat,
              lng: content.map.lon,
            }}
          >
            {/* single marker for current location */}
            <MapMarker
              lat={content.map.lat}
              lng={content.map.lon}
              onClick={() => setActiveLocation(activeLocation !== -1 ? -1 : null)}
              showCard={activeLocation === -1}
              renderInfoCard={() => (
                <InfoCard fields={content} slug={content.slug} current={true} />
              )}
              focused={activeLocation === -1}
            >
              <LocationMarkerSvg
                title={content.name}
                hideEyeOnPin={hideEyeIcon}
                classNames="text-primary-actual"
                focused={activeLocation === -1}
                markerIcon={markerIcon}
              />
            </MapMarker>
            {/* multiple markers */}
            {nearbyLocations &&
              nearbyLocations.map((location, i) => (
                <MapMarker
                  key={i}
                  lat={location.fields?.map.lat}
                  lng={location.fields?.map.lon}
                  onClick={() => setActiveLocation(i !== activeLocation ? i : null)}
                  showCard={activeLocation === i}
                  renderInfoCard={() => (
                    <InfoCard fields={location.fields} slug={location.fields.slug} />
                  )}
                  focused={activeLocation === i}
                >
                  <LocationMarkerSvg
                    title={location.fields?.name}
                    hideEyeOnPin={hideEyeIcon}
                    classNames="text-tertiary-actual"
                    focused={activeLocation === i}
                    markerIcon={markerIcon}
                  />
                </MapMarker>
              ))}
          </MapWrapper>
        </div>
      )}
    </div>
  );
}
