import React, { ReactElement } from 'react';
import Link from 'components/UI/Link';
import { Location } from 'components/UI/Map';

interface WindowProps extends Location {
  current?: boolean;
}

export default function InfoCard({ fields, slug, current }: WindowProps): ReactElement {
  return (
    <div
      className="relative bg-white p-3 shadow-xl rounded cursor-default z-30"
      style={{
        transform: 'translate(15%, 0)',
        minWidth: 200,
        bottom: 90,
        zIndex: 100,
      }}
    >
      {current ? (
        <span className="font-heading text-xl flex flex-col">
          {fields?.name}
          <span className="text-secondary-actual text-xs">Currently viewing</span>
        </span>
      ) : (
        <Link slug={`locations/${slug}`}>
          <a className="font-heading text-xl cursor-pointer underline">{fields?.name}</a>
        </Link>
      )}
      <div className="mt-2 flex flex-col text-sm">
        <span>{fields?.address1}</span>
        {fields?.address2 && <span className="mt-1">{fields?.address2}</span>}
        {fields?.address3 && <span className="mt-1">{fields?.address3}</span>}
        <span className="mt-1">{fields?.city + ', ' + fields?.state + ' ' + fields?.zipCode}</span>
      </div>
    </div>
  );
}
